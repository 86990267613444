<template>
  <div class="flex">
    <div class="bg-[#f1f8fe] h-screen w-full overflow-y-auto">
      <TopNavbar />
      <div class="w-full">
        <div class="flex flex-col p-4 md:px-8">
          <div class="flex gap-4 justify-center">
            <button
              class="px-4 py-2 rounded-full text-m text-gray-400"
              @click="selectedVal = 'windows'"
              :class="{
                'bg-blue-500 !text-white': selectedVal == 'windows',
              }"
            >
              Windows
            </button>
            <button
              @click="selectedVal = 'mac'"
              class="px-4 py-2 rounded-full text-m text-gray-400"
              :class="{
                'bg-blue-500 !text-white': selectedVal == 'mac',
              }"
            >
              Mac
            </button>
          </div>
          <div v-if="selectedVal == 'windows'">
            <ManualDetail />
          </div>
          <div v-else>
            <ManualDetailMac />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopNavbar from "@/components/NewComponents/TopNavbar.vue";
import ManualDetail from "@/components/Manual/ManualDetail.vue";
import ManualDetailMac from "@/components/Manual/ManualDetailMac.vue";
export default {
  name: "SettingsView",
  components: { TopNavbar, ManualDetail, ManualDetailMac },
  data() {
    return {
      selectedVal: "windows",
    };
  },
};
</script>

<style></style>
