<template>
  <div class="overflow-y-auto">
    <div class="flex justify-center">
      <div class="mainContainer">
        <div
          class="text-[#104d9b] w-full py-3 lg:pt-5 flex justify-center items-center"
        >
          <!-- <img src="assests/Group 127.svg" class="liveyLogo" alt="LiveyLogo" /> -->
          <h3 class="text-lg lg:text-3xl">LIVEYfy App Documentation for Mac</h3>
        </div>

        <div class="middleCotainer">
          <!-- <div class="headingContainer hidden">
            <p class="heading">Introduction</p>
            <p>
              This document provides step-by-step instructions for using the
              LIVEfy app, specifically focusing on configuring input and output
              devices and using the noise removal module.
            </p>
          </div>
          <div class="headingContainer py-3">
            <p class="heading">Steps to use LIVEYfy App</p>
          </div> -->

          <div class="flex gap-2 lg:gap-4 justify-center">
            <!-- <div class="containerLeft">
              <div
                class="flex flex-col justify-center items-center mt-[160px] lg:mt-[290px]"
              >
                <div class="circle">1</div>
                <div class="w-[2px] bg-[#2984ef] h-[420px] lg:h-[210px]"></div>
                <div class="circle">2</div>
                <div class="w-[2px] bg-[#2984ef] h-[520px] lg:h-[700px]"></div>
                <div class="circle">3</div>
                <div class="w-[2px] bg-[#2984ef] h-[420px] lg:h-[600px]"></div>
                <div class="circle">4</div>
                <div class="w-[2px] bg-[#2984ef] h-[370px] lg:h-[750px]"></div>
                <div class="circle">5</div>
                <div class="w-[2px] bg-[#2984ef] h-[420px] lg:h-[720px]"></div>
                <div class="circle">6</div>
              </div>
            </div> -->
            <!-- 1 -->
            <div class="flex flex-col gap-2 lg:gap-5">
              <div
                class="w-full h-auto m-[5px] p-[15px] lg:m-[10px] lg:p-8 lg:w-[700px] flex flex-col bg-white stepShadow rounded-md"
              >
                <p class="contentHeading">Audio Driver for liveyfy app</p>
                <p>
                  Install
                  <a
                    target="_blank"
                    href="https://deb-public.s3.ap-south-1.amazonaws.com/BlackHole+2ch/audio-driver.pkg"
                    class="text-sky-600"
                    >audio-driver.pkg</a
                  >
                </p>
                <img
                  src="@/assets/pre-installer.png"
                  alt="liveyfy"
                  class="w-full object-contain pt-3"
                />
              </div>
              <div
                class="w-full h-auto m-[5px] p-[15px] lg:m-[10px] lg:p-8 lg:w-[700px] flex flex-col bg-white stepShadow rounded-md"
              >
                <p class="contentHeading">LIVEYfy App for mac M1 & M2</p>
                <p>
                  Install
                  <a
                    class="text-sky-600"
                    target="_blank"
                    href="https://deb-public.s3.ap-south-1.amazonaws.com/mac-build-2025/LIVEYfy_0.3.5_aarch64.dmg"
                  >
                    LIVEYfy_0.3.5_aarch64.dmg
                  </a>
                </p>
                <!-- <img
                  src="@/assets/manual/app.png"
                  alt="liveyfy"
                  class="w-full object-contain pt-3"
                /> -->
              </div>
              <!-- 2 -->
              <div
                class="w-full h-auto m-[5px] p-[15px] lg:m-[10px] lg:p-8 lg:w-[700px] flex flex-col bg-white stepShadow rounded-md"
              >
                <p class="contentHeading">
                  Open your terminal, and run our liveyfy setup file for Mac
                </p>
                <p>
                  Liveyfy App Configuration file
                  <a
                    class="text-sky-500"
                    target="_blank"
                    href="https://deb-public.s3.ap-south-1.amazonaws.com/BlackHole+2ch/app-configuration-setup.sh"
                    >app-configuration-setup.sh</a
                  >
                </p>
                <p>Run following comments</p>
                <p>$ bash app-configuration-setup.sh</p>
              </div>
              <!-- 3 -->
              <div
                class="w-full h-auto m-[5px] p-[15px] lg:m-[10px] lg:p-8 lg:w-[700px] flex flex-col bg-white stepShadow rounded-md"
              >
                <p class="contentHeading">
                  Make sure blackhole-2ch is installed in your system and system
                  output
                </p>
                <p>
                  blackhole-2ch should not be muted. SYSTEM OUTPUT SHOULD NOT
                  BLACKHOLE-2ch
                </p>

                <img
                  src="@/assets/setting-screen.png"
                  alt="liveyfy"
                  class="w-full object-contain pt-3"
                />
              </div>
              <!-- 4 -->
              <div
                class="w-full h-auto m-[5px] p-[15px] lg:m-[10px] lg:p-8 lg:w-[700px] flex flex-col bg-white stepShadow rounded-md"
              >
                <p class="contentHeading">Select the microphone</p>
                <p>
                  In liveyfy app, select the microphone which is active and
                  toggle the noise cancellation button to activate liveyfy
                </p>
                <img
                  src="@/assets/app-screen.png"
                  alt="liveyfy"
                  class="w-full object-contain pt-3"
                />
              </div>
              <!-- 5 -->
              <div
                class="w-full h-auto m-[5px] p-[15px] lg:m-[10px] lg:p-8 lg:w-[700px] flex flex-col bg-white stepShadow rounded-md"
              >
                <p class="contentHeading">
                  Selecting select blackhole-2ch in Meetings
                </p>
                <p>
                  During a meeting , make sure to select the
                  <span class="text-[#f63e3e]">select blackhole-2ch</span>
                  as your microphone source.
                </p>
                <img
                  src="@/assets/meeting-screen.png"
                  alt="liveyfy"
                  class="w-full object-contain pt-3"
                />
              </div>
              <!-- 6 -->
              <!-- <div
                class="w-full h-auto m-[5px] p-[15px] lg:m-[10px] lg:p-8 lg:w-[700px] flex flex-col bg-white stepShadow rounded-md"
              >
                <p class="contentHeading">Immediate Closure</p>
                <p>
                  When the switch-off button is pressed, the application will
                  immediately close. This action terminates all active sessions
                  and processes.
                </p>

                <img
                  src="@/assets/manual/image-6.png"
                  alt="liveyfy"
                  class="w-full object-contain pt-3"
                />
              </div> -->
            </div>
          </div>
        </div>
        <!-- <div class="headingContainer pt-5 hidden">
          <p class="heading">conclusion</p>
          <p>
            Following these instructions will help ensure that you properly
            configure and use the LIVEYfy app for optimal performance during
            meetings. For any further assistance, refer to the app’s help
            section or contact support.
          </p>
        </div> -->
        <!-- <footer class="footerContent">
          <p class="greetings">Thank you for choosing LIVEYfy.</p>
          <p>
            We look forward to helping you achieve great results with our
            software.
          </p>
        </footer> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.mainContainer {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 10px;
  /* background-color: #f5f5f5 */
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.circle {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  color: #2984ef;
  display: flex;
  font-weight: bolder;
  justify-content: center;
  align-items: center;
  font-size: larger;
  border: 2px solid #2984ef;
}

.line {
  width: 2px;
  height: 400px;
  background-color: #2984ef;
}

.logo {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  background: #104d9b;
  padding: 30px 0px 30px 0px;
}

.liveyLogo {
  width: 300px;
  height: auto;
  color: white !important;
}

.headingContainer {
  display: flex;
  flex-direction: column;
  width: 790px;
}

.box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  flex-wrap: wrap;
}
.middleCotainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* padding: 20px; */
  padding-top: 20px;
}

.heading {
  font-size: 20px !important;
}

/* .contentBox {
  width: 700px;
  height: 500px;
  background-color: white;
  margin: 10px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
} */
.stepShadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

.contentHeading {
  font-size: 20px !important;
  font-weight: 400;
  margin-bottom: 10px;
}
.middleCotainer p {
  line-height: 1.5;
  font-size: 15px;
}
.downloadContent {
  font-weight: bold;
}

.lastContainer {
  width: 100%;
  text-align: center;
  font-size: 16px;
  background-color: white;
}
/* .mailId {
  color: #5797e7;
  text-decoration: none;
  font-size: 20px;
  font-weight: 6000;
} */
/* .footerContent {
  text-align: center;
  padding: 20px;
} */
/* .greetings {
  font-size: 20px;
  font-weight: 600;
} */

/* Media query for mobile view */
@media (max-width: 768px) {
  .mainContainer {
    width: 100%;
    padding: 5px;
  }

  .container {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .circle {
    width: 25px;
    height: 25px;
    font-size: medium;
  }

  .line {
    height: 100px;
  }

  .logo {
    padding: 20px 0px 20px 0px;
  }

  .liveyLogo {
    width: 200px;
  }

  .headingContainer {
    width: 100%;
  }

  .box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  /* .contentBox {
    width: 100%;
    height: auto;
    padding: 15px;
    margin: 5px;
  } */

  .contentHeading {
    font-size: 18px !important;
  }

  .middleCotainer p {
    font-size: 14px;
  }
  .lastContainer {
    font-size: 14px;
  }

  /* .footerContent {
    padding: 15px;
  }

  .greetings {
    font-size: 18px;
  } */
}
</style>
