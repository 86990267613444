<template>
  <div class="flex">

    <div class="flex-1 bg-[#F1F8FE] w-full">
      <TopBar />

      <div class="p-4 md:px-8 overflow-y-auto">
        <div class="block md:flex md:space-x-3 h-auto pt-10">
          <div
            class="w-full md:w-[65%] p-3 bg-white border rounded-xl leading-10 text-[14px] px-4 md:px-10 mb-5"
          >
            <p class="text-gray-700 flex justify-start text-[18px] py-5">
              Overall Stats
            </p>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-10">
              <!-- Stat Cards -->
              <div class="block text-start w-full text-gray-700 lato">
                <img
                  src="../assets/Vector.png"
                  alt=""
                  class="w-[20px] h-[20px] md:w-auto md:h-auto"
                />
                <p>Meeting Numbers</p>
                <b class="font-bold text-[28px] text-[#000000]">0</b>
              </div>
              <div class="block text-start w-full text-gray-700">
                <img
                  src="../assets/Group_178.png"
                  alt=""
                  class="w-[20px] h-[20px] md:w-auto md:h-auto"
                />
                <p>Meeting Hours Used</p>
                <b class="font-bold text-[28px] text-[#000000]">0 hrs</b>
              </div>
              <div class="block text-start w-full text-gray-700">
                <img
                  src="../assets/Group_181.png"
                  alt=""
                  class="w-[20px] h-[20px] md:w-auto md:h-auto"
                />
                <p>Total Device Used</p>
                <b class="font-bold text-[28px] text-[#000000]">0</b>
              </div>
            </div>
          </div>

          <!-- Second Column -->
          <div
            class="w-full md:w-[35%] p-3 bg-white border rounded-xl leading-10 text-[14px] px-4 md:px-10 mb-5 space-y-3"
          >
            <h1 class="text-start text-[18px] text-gray-700">
              Recent Activity
            </h1>
            <!-- Recent Activity Items -->
            <div class="flex flex-col gap-y-5 text-sm">
              <div class="flex text-start gap-x-2 items-start border-b p-2">
                <img
                  src="../assets/Vector2.png"
                  class="h-5 w-5 object-contain"
                  alt=""
                />
                <p>0 New Users Registered</p>
              </div>
              <div class="flex text-start gap-x-2 items-start border-b p-2">
                <img
                  src="../assets/Vector2.png"
                  class="h-5 w-5 object-contain"
                  alt=""
                />
                <p>LIVEYfy users engaged in 0 meetings</p>
              </div>
              <div class="flex text-start gap-x-2 items-start border-b p-2">
                <img
                  src="../assets/Vector2.png"
                  class="h-5 w-5 object-contain"
                  alt=""
                />
                <p>0 users are currently utilizing noise cancellation</p>
              </div>
            </div>
          </div>
        </div>

        <div class="md:flex md:gap-x-5 md:w-[100%] w-full">
          <div
            class="w-full bg-white h-auto border rounded-xl p-5 overflow-x-scroll md:overflow-x-hidden"
          >
            <!-- Search and Select -->
            <div class="md:flex justify-between w-full">
              <div>
                <h1>Total Members</h1>
                <p class="font-bold text-2xl">{{ this.user_count }}</p>
              </div>
              <div
                class="flex flex-col md:flex-row gap-y-1 gap-x-2 justify-center"
              >
                <div
                  class="flex relative items-center h-8 w-auto outline-none gap-x-2 px-2 border rounded-md bg-gray-100"
                >
                  <img
                    src="../assets/search.png"
                    class="h-4 w-4"
                    alt=""
                  /><input
                    type="text"
                    placeholder="Search..."
                    class="outline-none bg-gray-100 w-full md:w-auto"
                  />
                </div>
                <select
                  name=""
                  id=""
                  class="rounded-md px-2 h-8 w-full md:w-auto"
                >
                  <option value="">Today</option>
                  <option value="">Last Week</option>
                  <option value="">Last Month</option>
                </select>
              </div>
            </div>

            <!-- Table -->
            <div class="overflow-x-auto flex gap-x-5 h-[calc(100vh-380px)]">
              <table class="text-left inline-block w-full">
                <thead class="p-5 text-gray-700 font-medium w-full">
                  <tr class="">
                    <th class="p-2 text-sm">Name</th>
                    <th class="p-2 text-sm">Email</th>
                    <th class="p-2 text-sm">Phone no</th>
                    <th class="p-2 text-sm">User Name</th>
                    <th class="p-2 text-sm">Login info</th>
                  </tr>
                </thead>
                <tbody class="w-full">
                  <!--  <tr class=" text-left" v-for="(id, index) in 10" :key="id">
               <td class="p-2">{{ index + 1 }}</td> 
             {{ index + 1 }}-->
                  <!-- <tr>
                <td colspan="5" class="text-center p-5">No Data Found</td>
              </tr> -->
                  <tr
                    class="text-left border-b"
                    v-for="user in user_list"
                    :key="user"
                  >
                    <td class="flex p-2 items-center gap-x-2 capitalize">
                      <span
                        class="px-2 py-1 rounded-full text-xs text-white bg-[#2a84ef] h-8 w-8 flex items-center justify-center"
                        >{{ user.user_name.slice(0, 1) }}</span
                      >
                      <p class="text-sm">{{ user.user_name }}</p>
                    </td>
                    <td class="p-2 text-sm">{{ user.user_email }}</td>
                    <td class="p-2 text-sm">{{ user.phone_number }}</td>
                    <td class="p-2 text-sm">
                      {{ user.username ? user.username : "--" }}
                    </td>
                    <td class="relative" @click="loginInfo(user.user_id)">
                      <button class="px-2 py-0.5 text-sm rounded-md border">
                        View
                      </button>
                    </td>
                  </tr>
                </tbody>
                <PaginationView
                  :total_number_page="13"
                  :count_per_page="5"
                  @selectedPage="selectPageCall"
                  class="hidden"
                />
              </table>
              <div
                class="w-full md:w-[20%] bg-[#FAFAFA] h-auto border rounded-xl p-5 space-y-2"
              >
                <!-- New User Section -->
                <p class="py-1 flex text-start font-medium text-base">
                  New User
                </p>
                <div class="flex flex-col text-sm">
                  <div class="flex justify-between text-sm">
                    <p class="text-semibold text-[#000000]">Sign in</p>
                    <p class="text-semibold text-[#000000]">0</p>
                  </div>
                  <div class="flex justify-between py-1 text-sm">
                    <p class="text-gray-700">Individual</p>
                    <p class="text-gray-700">06</p>
                  </div>
                  <div class="flex justify-between py-1 text-sm">
                    <p class="text-gray-700">Organization</p>
                    <p class="text-gray-700">0</p>
                  </div>
                  <hr class="my-5" />
                  <div class="flex justify-between py-1">
                    <p class="text-semibold text-[#000000]">Login</p>
                    <p class="text-semibold text-[#000000]">0</p>
                  </div>
                  <div class="flex justify-between py-1">
                    <p class="text-gray-700">Individual</p>
                    <p class="text-gray-700">0</p>
                  </div>
                  <div class="flex justify-between py-1">
                    <p class="text-gray-700">Organization</p>
                    <p class="text-gray-700">0</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="is_login_info"
      class="fixed top-0 left-0 h-full w-full bg-black bg-opacity-50 flex items-center justify-center"
    >
      <div
        class="bg-white min-w-[720px] min-h-[520px] p-6 flex flex-col gap-2 relative"
      >
        <span
          class="absolute top-4 right-4 text-xl text-red-700 cursor-pointer"
          @click="is_login_info = false"
          >x</span
        >
        <h1 class="text-center text-3xl">User Login Info</h1>
        <table class="w-full text-left rounded-md">
          <thead class="p-5 text-white rounded-xl h-14">
            <tr class="bg-[#2a84ef] rounded-xl h-10 px-2">
              <th class="p-2 text-sm roboto-light font-medium rounded-md">
                Name
              </th>
              <th class="p-2 text-sm roboto-light font-medium">Source</th>
              <th class="p-2 text-sm roboto-light font-medium">
                Login date & time
              </th>
            </tr>
          </thead>
          <tbody class="w-full">
            <!--  <tr class=" text-left" v-for="(id, index) in 10" :key="id">
               <td class="p-2">{{ index + 1 }}</td> 
             {{ index + 1 }}-->
            <!-- <tr>
                <td colspan="5" class="text-center p-5">No Data Found</td>
              </tr> -->
            <template v-if="login_info.length > 0">
              <tr
                class="text-left py-2 border rounded-lg"
                v-for="(user, index) in login_info"
                :key="user"
                :class="(index + 1) % 2 == 0 ? 'bg-gray-200' : 'bg-white'"
              >
                <td class="flex p-2 items-center gap-x-2">
                  <span
                    class="px-2 py-1 rounded-full text-xs text-white bg-[#2a84ef] h-6 w-6 flex items-center justify-center"
                    >{{ user.first_name.slice(0, 1) }}
                  </span>
                  <p class="text-sm">{{ user.first_name }}</p>
                </td>
                <td class="p-2 text-sm">{{ user.login_source }}</td>
                <td class="p-2 text-sm">
                  {{ new Date(user.login_date_time).toLocaleString("en-GB") }}
                </td>
              </tr>
            </template>
            <template v-else>
              <tr class="text-center">
                <td colspan="3">No data</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from "@/components/NewComponents/TopNavbar.vue";
import axios from "axios";
import { mapGetters } from "vuex";
import PaginationView from "@/components/PaginationView.vue";

export default {
  components: {  TopBar, PaginationView },
  data() {
    return {
      login_info: [],
      user_list: [],
      user_count: 0,
      is_login_info: false,
    };
  },
  computed: { ...mapGetters(["getUser", "getToken"]) },
  created() {
    console.log(this.getUser, this.getToken);
    // this.loginInfo();
    this.userGetList();
    // this.postUserLoginInfo();
  },
  methods: {
    selectPageCall(val) {
      console.log(val);
    },
    userGetList() {
      axios
        .get(`${process.env.VUE_APP_API}/api/user-list/?Key=${this.getToken}`)
        .then((response) => {
          console.log(response);
          this.user_list = response.data.results;
          this.user_count = response.data.count;
        })
        .catch((er) => {
          console.log(er);
        });
    },
    loginInfo(id) {
      axios
        .get(
          `${process.env.VUE_APP_API}/api/login-info/${id}/?Key=${this.getToken}`
        )
        .then((response) => {
          console.log(response, "login User");
          this.login_info = response.data;
          this.is_login_info = true;
        })
        .catch((er) => {
          console.log(er);
        });
    },
  },
};
</script>
<style scoped>
table {
  overflow: hidden;
  border-radius: 15px;
}
</style>
