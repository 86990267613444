<template>
  <nav class="relative px-6 py-3 flex items-center justify-between">
    <div
      class="relative flex items-start rounded-[20px] bg-blue-100 px-3.5 py-2"
    >
      <a href="#" class="text-blue-500 font-bold text-2xl tracking-tight"
        ><img src="@/assets/Logo.svg" alt="img" class="w-28"
      /></a>
    </div>

    <div
      class="relative bg-blue-100 flex items-center space-x-1 mx-auto rounded-full"
    >
      <router-link
        v-if="this.getUser.is_superuser"
        :to="{ name: 'AllUsersInfo' }"
        class="flex items-center px-3.5 py-2.5 rounded-full text-m text-gray-400"
        :class="{ 'bg-blue-500 !text-white': $route.name === 'AllUsersInfo' }"
      >
        <svg
          class="h-5 w-5 mr-2"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
        >
          <rect x="3" y="3" width="7" height="7" />
          <rect x="14" y="3" width="7" height="7" />
          <rect x="14" y="14" width="7" height="7" />
          <rect x="3" y="14" width="7" height="7" />
        </svg>
        Dashboard
      </router-link>
      <router-link
        v-else-if="getUser.permission == 'admin'"
        :to="{ name: 'AdminDash' }"
        class="flex items-center px-3.5 py-2.5 rounded-full text-m text-gray-400"
        :class="{ 'bg-blue-500 !text-white': $route.name === 'AdminDash' }"
      >
        <svg
          class="h-5 w-5 mr-2"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
        >
          <rect x="3" y="3" width="7" height="7" />
          <rect x="14" y="3" width="7" height="7" />
          <rect x="14" y="14" width="7" height="7" />
          <rect x="3" y="14" width="7" height="7" />
        </svg>
        Dashboard
      </router-link>
      <router-link
        v-else
        to="/user-dashboard"
        class="flex items-center px-3.5 py-2.5 rounded-full text-m text-gray-400"
        :class="{ 'bg-blue-500 !text-white': $route.name === 'DashBoard' }"
      >
        <svg
          class="h-5 w-5 mr-2"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
        >
          <rect x="3" y="3" width="7" height="7" />
          <rect x="14" y="3" width="7" height="7" />
          <rect x="14" y="14" width="7" height="7" />
          <rect x="3" y="14" width="7" height="7" />
        </svg>
        Dashboard
      </router-link>

      <div v-if="this.getUser.is_superuser" class="flex flex-col gap-y-1">
        <router-link
          :to="{ name: 'OrganizationsInfo' }"
          class="flex items-center px-4 py-2 rounded-full text-m text-gray-400"
          :class="{
            'bg-blue-500 !text-white': $route.name === 'OrganizationsInfo',
          }"
        >
          <svg
            class="h-5 w-5 mr-2"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
          >
            <path
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
            />
            <path
              d="M12 6c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm0 10c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z"
            />
          </svg>
          Organization Info
        </router-link>
      </div>

      <router-link
        :to="{ name: 'DownloadPage' }"
        class="flex items-center px-4 py-2 rounded-full text-m text-gray-400"
        :class="{ 'bg-blue-500 !text-white': $route.name === 'DownloadPage' }"
      >
        <svg
          class="h-5 w-5 mr-2"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
        >
          <path d="M19 9l-7 7-7-7" />
          <path d="M12 16V4" />
        </svg>
        Download APP
      </router-link>

      <router-link
        :to="{ name: 'ManualView' }"
        class="flex items-center px-4 py-2 rounded-full text-m text-gray-400"
        :class="{ 'bg-blue-500 !text-white': $route.name === 'ManualView' }"
      >
        <svg
          class="h-5 w-5 mr-2"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
        >
          <path
            d="M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H20v20H6.5a2.5 2.5 0 0 1 0-5H20"
          />
        </svg>
        Manual
      </router-link>
      <template v-if="isAuthenticated && this.getUser.permission == 'admin'">
        <router-link
          :to="{ name: 'SettingView' }"
          class="flex items-center px-4 py-2 rounded-full text-m text-gray-400"
          :class="{ 'bg-blue-500 !text-white': $route.name === 'SettingView' }"
        >
          <svg
            class="h-5 w-5 mr-2"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
          >
            <path
              d="M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H20v20H6.5a2.5 2.5 0 0 1 0-5H20"
            />
          </svg>

          Settings
        </router-link>
      </template>
    </div>
    <div class="relative flex items-center space-x-6">
      <button
        class="text-gray-500 hidden hover:text-gray-700 bg-blue-100 px-2 py-2 rounded-full"
      >
        <svg
          class="h-5 w-5"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
        >
          <circle cx="11" cy="11" r="8" />
          <path d="M21 21l-4.35-4.35" />
        </svg>
      </button>

      <button
        class="text-gray-500 hidden hover:text-gray-700 bg-blue-100 px-2 py-2 rounded-full"
      >
        <svg
          class="h-5 w-5"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
        >
          <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9" />
          <path d="M13.73 21a2 2 0 0 1-3.46 0" />
        </svg>
      </button>

      <div class="flex gap-6 mr-4">
        <button
          class="focus:outline-none hidden md:flex p-2 rounded-md bg-primary text-white h-10 w-10 flex items-center justify-center capitalize"
          @click="toggleButton"
        >
          <!-- <img src="../assets/Rectangle_313.png" class="object-contain" alt="" /> -->
          <span>{{
            this.getUser ? this.getUser.display_name.slice(0, 1) : "Null"
          }}</span>
        </button>
      </div>
      <div
        v-if="button_logout"
        class="flex flex-col absolute z-[100] w-44 top-[62px] right-2 items-start bg-[#ffffff] border lato rounded-md"
      >
        <router-link
          v-if="getUser.permission == 'admin'"
          :to="{ name: 'AdminProfile' }"
          class="flex w-full text-center justify-center hover:bg-gray-100 px-5 py-2 text-sm border-b"
        >
          Profile
        </router-link>
        <router-link
          v-if="
            getUser.permission == 'external' || getUser.permission == 'employee'
          "
          :to="{ name: 'UserProfile' }"
          class="flex w-full text-center justify-center hover:bg-gray-100 px-5 py-2 text-sm border-b"
        >
          Profile
        </router-link>
        <router-link
          v-if="
            getUser.permission == 'external' || getUser.permission == 'employee'
          "
          :to="{ name: 'UserSubscriptionsInfo' }"
          class="flex w-full text-center justify-center hover:bg-gray-100 px-5 py-2 text-sm border-b"
        >
          Subscriptions
        </router-link>
        <router-link
          :to="{ name: 'UpdatePassword' }"
          class="flex w-full text-center justify-center hover:bg-gray-100 px-5 py-2 text-sm border-b"
        >
          Change Password
        </router-link>
        <button
          class="flex w-full text-center justify-center hover:bg-gray-100 px-5 py-2 text-sm"
          @click="logOut"
        >
          Logout
        </button>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      isSidebarOpen: true,
      isProfileMenuOpen: false,
      button_logout: false,
      // name: this.getUser.display_name
    };
  },
  computed: {
    ...mapGetters(["getUser", "isAuthenticated"]),
  },
  methods: {
    ...mapActions(["logout"]),
    logOut() {
      this.logout();
      this.$router.push({ name: "login" });
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    toggleProfileMenu() {
      this.isProfileMenuOpen = !this.isProfileMenuOpen;
    },
    closeProfileMenu(event) {
      if (!event.target.closest(".relative")) {
        this.isProfileMenuOpen = false;
      }
    },
    toggleButton() {
      this.button_logout = !this.button_logout;
    },
  },
  mounted() {
    window.addEventListener("click", this.closeProfileMenu);
  },
  beforeUnmount() {
    window.removeEventListener("click", this.closeProfileMenu);
  },
};
</script>

<style></style>
