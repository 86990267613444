<template>
  <div>
    <TopNavbar />
    <div class="p-8">
      <h1 class="text-[22px] font-bold text-gray-900 mb-6">User Details</h1>
      <div class="relative">
        <div class="flex items-center justify-between">
          <div
            class="w-full pl-7 flex items-center h-20 rounded-tl-3xl bg-blue-100"
          >
            <span class="text-lg font-medium">{{ totalCount }} Users</span>
          </div>
          <div class="h-20 w-72 rounded-tr-full bg-blue-100"></div>
          <div
            class="flex h-20 items-center gap-2 w-auto bg-white p-3 px-5 -mx-[9px] rounded-b-[50px] relative"
          >
            <button
              @click="(this.license_status = ''), getUserInfo()"
              class="px-1 w-20 py-3 rounded-full text-xs"
              :class="[
                (this.license_status == ''
                  ? 'bg-blue-500 text-white'
                  : 'bg-blue-50 text-blue-500'),
              ]"
            >
              All Users
            </button>
            <button
              @click="(this.license_status = 'active'), getUserInfo()"
              class="px-4 py-3 w-20 rounded-full text-xs font-medium"
              :class="[
                (this.license_status == 'active'
                  ? 'bg-blue-500 text-white'
                  : 'bg-blue-50 text-blue-500'),
              ]"
            >
              Active
            </button>
            <button
              @click="(this.license_status = 'expired'), getUserInfo()"
              class="px-4 w-20 py-3 rounded-full text-xs font-medium"
              :class="[
                (this.license_status == 'expired'
                  ? 'bg-blue-500 text-white'
                  : 'bg-blue-50 text-blue-500'),
              ]"
            >
              Expired
            </button>
            <span
              class="absolute w-96 -z-20 h-9 left-1/2 -translate-x-1/2 -bottom-1 bg-blue-100"
            ></span>
          </div>
          <div class="h-20 w-72 rounded-tl-full bg-blue-100"></div>
          <div
            class="flex w-full p-7 rounded-tr-3xl h-20 bg-blue-100 justify-end items-center gap-2"
          >
            <div class="relative">
              <input
                type="text"
                placeholder="Search users here....."
                class="w-[180px] pl-10 pr-4 py-2 rounded-full bg-white shadow-sm text-sm"
              />
              <svg
                class="w-4 h-4 absolute left-4 top-1/2 -translate-y-1/2 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <circle cx="11" cy="11" r="8" />
                <path d="M21 21l-4.35-4.35" />
              </svg>
            </div>
            <button class="text-gray-600 bg-white rounded-full px-1 py-1">
              <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div class="bg-blue-100 rounded-b-[40px] relative p-6">
        <div class="bg-white rounded-xl shadow-sm p-3">
          <table class="w-full border-separate border-spacing-y-3">
            <thead>
              <tr class="text-left bg-blue-100">
                <th
                  class="py-4 px-4 rounded-l-xl text-sm font-medium text-gray-700"
                >
                  No
                </th>
                <th class="text-sm font-medium text-gray-700">User</th>
                <th class="py-4 px-4 text-sm font-medium text-gray-700">
                  Email
                </th>
                <th class="py-4 px-4 text-sm font-medium text-gray-700">
                  Phone Number
                </th>
                <th class="py-4 px-4 text-sm font-medium text-gray-700">
                  Access Type
                </th>
                <th class="py-4 px-4 text-sm font-medium text-gray-700">
                  Organization
                </th>
                <th class="py-4 px-4 text-sm font-medium text-gray-700">
                  Joined Date
                </th>
                <th class="py-4 px-4 text-sm font-medium text-gray-700">
                  Expiry Date
                </th>
                <th
                  class="py-4 px-4 text-sm font-medium rounded-r-xl text-gray-700"
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody class="">
              <tr
                class="text-left py-2 border rounded-lg cursor-pointer hover:bg-blue-500 hover:text-white group transition-colors"
                @click="(popUp = true), (select_user = user)"
                v-for="(user, index) in user_info"
                :key="user"
                :class="(index + 1) % 2 == 0 ? 'bg-gray-200' : 'bg-white'"
              >
                <td class="px-4 py-2 text-sm rounded-l-xl">
                  {{ index + 1 + this.index_value }}
                </td>
                <td class="gap-3">
                  <div class="flex items-center">
                    <span
                      class="bg-blue-500 w-7 mr-1 flex justify-center items-center group-hover:bg-white group-hover:text-blue-500 h-7 text-center group-hover:font-semibold place-content-center rounded-full text-sm text-white"
                      >{{ user.first_name.slice(0, 1) }}</span
                    >
                    <span class="text-sm font-medium">
                      {{ user.first_name }}</span
                    >
                  </div>
                </td>
                <td class="px-4 py-2 text-sm">{{ user.email }}</td>
                <td class="px-4 py-2 text-sm">{{ user.phone }}</td>
                <td class="px-4 py-2 text-sm">{{ user.Permission_access }}</td>
                <td class="px-4 py-2 text-sm">{{ user.organization }}</td>
                <td class="px-4 py-2 text-sm">
                  {{ new Date(user.date_joined).toLocaleDateString("en-GB") }}
                </td>
                <td class="px-4 py-2 text-sm">
                  {{ new Date(user.expiry_date).toLocaleDateString("en-GB") }}
                </td>
                <td class="px-4 py-2 rounded-r-xl">
                  <span
                    class="px-4 py-1.5 rounded-full text-xs font-medium text-white"
                    :class="[
                      user.status == 'active' ? 'bg-green-500' : 'bg-red-500',
                    ]"
                    >{{ user.status }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="flex items-center justify-between px-2 mt-3">
          <div class="flex items-center gap-2 text-sm text-gray-600">
            <span>Show</span>
            <span class="bg-white rounded-full px-2 py-2"
              ><option class="font-bold">10</option></span
            >

            <span>entries</span>
          </div>

          <div class="p-4 rounded-lg">
            <div class="flex items-center gap-4">
              <span class="text-sm text-gray-400">
                Showing 1 to 10 of 121 entries
              </span>
              <div class="flex items-center gap-2">
                <div class="mx-auto my-4">
                  <PaginationViewVue
                    @selectedPage="getUserInfo"
                    :total_number_page="this.total_page"
                    :count_per_page="this.count_per_page"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import TopNavbar from "@/components/NewComponents/TopNavbar.vue";
import PaginationViewVue from "@/components/PaginationPageView.vue";
export default {
  data() {
    return {
      user_info: [],
      count_per_page: 30,
      total_page: "",
      index_value: 30,
      license_status: "",
      popUp: false,
      select_user: [],
      update: {
        new: "",
        confirm: "",
      },
      message: {
        text: "",
        status: "",
      },
      loading: false,
      is_reset_password: false,
      totalCount: 0,
    };
  },

  components: { TopNavbar, PaginationViewVue },
  created() {
    this.getUserInfo();
  },
  methods: {
    updatePassword() {
      this.loading = true;
      if (this.update.new == this.update.confirm) {
        let payLoad = {
          new_password: this.update.new,
          confirm_password: this.update.confirm,
        };
        axios
          .patch(
            `${process.env.VUE_APP_API}/api/admin/users/${this.select_user.id}/reset-password/`,
            payLoad
          )
          .then((response) => {
            console.log(response);
            this.message.text = "Password reset successfully";
            this.message.status = 200;
            this.update.new = "";
            this.update.confirm = "";
            setTimeout(() => {
              this.loading = false;
              this.message.text = "";
              this.message.status = "";
            }, 3000);
          })
          .catch((error) => {
            console.log(error);
            this.message.text = "Try again ";
            this.message.status = 400;
            setTimeout(() => {
              this.loading = false;
              this.message.text = "";
              this.message.status = "";
            }, 3000);
          });
      } else {
        console.log("Password Not Same");

        this.message.text = "New password and confirm password are not same";
        this.message.status = 400;
        setTimeout(() => {
          this.loading = false;
          this.message.text = "";
          this.message.status = "";
        }, 3000);
      }
    },
    getUserInfo(pageNumber = 1) {
      console.log(pageNumber);
      this.current_page = pageNumber;
      this.index_value = (pageNumber - 1) * this.count_per_page;
      axios
        .get(
          `${process.env.VUE_APP_API}/api/list-users-all/?Key=0d27198fa776de1bec62f55ccb68ae199b477ce5&page=${this.current_page}&status=${this.license_status}`
        )
        .then((response) => {
          console.log(response);

          this.user_info = response.data.results;
          this.totalCount = response.data.count;
          console.log(response.data.count, "user count: ");
          this.total_page = Math.ceil(
            response.data.count / this.count_per_page
          );
        });
    },
  },
};
</script>

<style></style>
