<template>
  <div class="flex bg-gray-50 h-screen">
    <div class="w-full bg-[#f1f8fe]">
      <TopNavbar />
      <section
        class="flex flex-col justify-center h-[80%] w-full items-center gap-y-3 relative"
      >
        <div class="flex gap-4 justify-center">
          <button
            class="px-4 py-2 rounded-full text-m text-gray-400"
            @click="selectedVal = 'windows'"
            :class="{
              'bg-blue-500 !text-white': selectedVal == 'windows',
            }"
          >
            Windows
          </button>
          <button
            @click="selectedVal = 'mac'"
            class="px-4 py-2 rounded-full text-m text-gray-400"
            :class="{
              'bg-blue-500 !text-white': selectedVal == 'mac',
            }"
          >
            Mac
          </button>
        </div>
        <h1 class="text-lg md:text-3xl py-4">Download LIVEYfy App</h1>
        <img src="../assets/meeting.svg" class="h-60" alt="" />
        <div v-if="selectedVal == 'windows'" class="flex gap-2">
          <button
            @click="downloadDataPost('windows')"
            class="rounded-md bg-primary text-white gap-x-1 flex items-center p-2"
          >
            <font-awesome-icon icon="download" />
            <p>Download for Windows</p>
          </button>
        </div>
        <div v-else class="flex gap-2">
          <a
            target="_blank"
            href="https://deb-public.s3.ap-south-1.amazonaws.com/BlackHole+2ch/audio-driver.pkg"
            class="rounded-md bg-primary text-white gap-x-3 flex items-center justify-center p-2 min-w-[218px]"
            ><span class="px-2 text-black rounded-full bg-white">1</span>
            <font-awesome-icon icon="download" />
            <p>Audio Driver</p>
          </a>
          <a
            target="_blank"
            href="https://deb-public.s3.ap-south-1.amazonaws.com/mac-build-2025/LIVEYfy_0.3.5_aarch64.dmg"
            class="rounded-md bg-primary text-white gap-x-3 flex items-center justify-center p-2 min-w-[218px]"
          >
            <span class="px-2 text-black rounded-full bg-white">2</span>
            <font-awesome-icon icon="download" />
            <p>Liveyfy App</p>
          </a>
          <a
            target="_blank"
            href="https://deb-public.s3.ap-south-1.amazonaws.com/livefy-mac-post-installer.sh"
            class="rounded-md bg-primary text-white gap-x-3 flex items-center p-2 min-w-[218px]"
          >
            <span class="px-2 text-black rounded-full bg-white">3</span>
            <font-awesome-icon icon="download" />
            <p>App Configuration setup</p>
          </a>
        </div>
        <p v-if="selectedVal == 'mac'">
          Complete all three setup for seamless experience
        </p>
        <!-- <a
          href="https://deb-public.s3.ap-south-1.amazonaws.com/LIVEYfy_0.3.5_aarch64.dmg"
          target="_blank"
        >
          <p>available for <button class="text-primary">Mac</button></p>
        </a> -->
        <button class="text-primary underline" @click="showRequirement">
          See System Requirements
        </button>

        <!-- Requirements section -->
        <div
          v-show="requirements"
          class="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50"
        >
          <div class="absolute inset-0 bg-black opacity-50"></div>
          <div
            v-show="requirements"
            class="absolute bg-white w-full md:w-1/2 h-auto rounded-lg shadow-xl p-5"
          >
            <h1 class="text-xl">Requirements</h1>
            <div class="flex py-2">
              <button
                v-if="selectedVal == 'mac'"
                class="px-5 p-3 rounded-md min-w-[105px] bg-primary text-white"
              >
                Mac</button
              ><button
                v-else
                class="px-5 py-3 rounded-md min-w-[105px] bg-primary text-white"
              >
                Windows
              </button>
            </div>
            <section class="border text-left h-auto p-5">
              <div v-if="selectedVal == 'mac'">
                <table class="border w-full h-full">
                  <tr>
                    <th class="border px-5">OS</th>
                    <td class="border px-5">
                      <li>macOS version 10.3 Onwards</li>
                    </td>
                  </tr>
                  <tr>
                    <th class="border px-5">CPU</th>
                    <td class="border px-5">
                      <li>Intel Core i5</li>
                      <li>Apple silicon chip</li>
                    </td>
                  </tr>
                  <tr>
                    <th class="border px-5">RAM</th>
                    <td class="border px-5"><li>8GB RAM or higher</li></td>
                  </tr>
                </table>
              </div>
              <div v-else>
                <table class="border w-full h-full">
                  <tr>
                    <th class="border px-5">OS</th>
                    <td class="border px-5"><li>64 bit Windows 10</li></td>
                  </tr>
                  <tr>
                    <th class="border px-5">CPU</th>
                    <td class="border px-5">
                      <l>
                        <li>Intel core i5</li>
                        <li>AMD Ryzen 5</li>
                      </l>
                    </td>
                  </tr>
                  <tr>
                    <th class="border px-5">RAM</th>
                    <td class="border px-5"><li>8GB RAM or higher</li></td>
                  </tr>
                </table>
              </div>
            </section>
            <button class="absolute top-2 right-2 clbtn" @click="closeReq">
              Close
            </button>
          </div>
        </div>
      </section>
    </div>
    <!-- <Footer /> -->
  </div>
</template>

<script>
import TopNavbar from "@/components/NewComponents/TopNavbar.vue";
// import Footer from "@/components/FooterVIew.vue";
import axios from "axios";

import { mapGetters } from "vuex";

export default {
  name: "DownloadView",
  data() {
    return {
      requirements: false,
      showMac: false,
      showWindow: true,
      selectedVal: "windows",
    };
  },
  computed: { ...mapGetters(["getUser"]) },

  methods: {
    downloadDataPost(download_file) {
      var a = document.createElement("a");
      if (download_file === "mac") {
        a.href =
          "https://deb-public.s3.ap-south-1.amazonaws.com/LIVEYfy_0.3.5_aarch64.dmg";
        a.target = "_blank";
        a.click();
      } else {
        a.href =
          "https://deb-public.s3.ap-south-1.amazonaws.com/LIVEYfy_0.3.5_x64-setup-24-Aug-release.exe";
        a.target = "_blank";
        a.click();
      }
      let json_data = {
        user: this.getUser.id,
        device: download_file,
        download_date_time: new Date(),
      };
      axios
        .post(
          `${process.env.VUE_APP_API}/api/create-download-history/`,
          json_data
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showRequirement() {
      this.requirements = true;
    },
    showMacSection() {
      this.showMac = true;
      this.showWindow = false;
    },
    showWindowsSection() {
      this.showMac = false;
      this.showWindow = true;
    },
    closeReq() {
      this.requirements = false;
    },
  },
  components: {
    TopNavbar,
    // Footer,
  },
};
</script>

<style scoped>
tr,
td {
  padding: 28px;
}
</style>
