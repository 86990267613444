<template>
  <div class="flex">
    
    <div class="bg-[#f1f8fe] h-screen w-full">
      <TopNavbar />
      <div class="flex flex-col p-4 md:px-8">
        <!-- Card View -->
        <div>
          <div class="flex justify-between gap-8 flex-wrap">
            <template v-if="org_info">
              <div
                class="border w-[220px] h-[80px] flex flex-col items-center justify-center rounded-md bg-white"
              >
                <p class="font-bold text-2xl text-gray-600">
                  {{ this.org_info.total_limit }}
                </p>
                <h1 class="text-sm text-gray-600">Total Licenses</h1>
              </div>
              <div
                class="border w-[220px] h-[80px] flex flex-col items-center justify-center rounded-md bg-white"
              >
                <p class="font-bold text-2xl text-gray-600">
                  {{ this.org_info.used_limit }}
                </p>
                <h1 class="text-sm text-gray-600">Total Users</h1>
              </div>
              <div
                class="border w-[220px] h-[80px] flex flex-col items-center justify-center rounded-md bg-white"
              >
                <p class="font-bold text-2xl text-gray-600">
                  {{ this.org_info.current_limit }}
                </p>
                <h1 class="text-sm text-gray-600">Available Licenses</h1>
              </div>
              <div
                class="border w-[220px] h-[80px] flex flex-col items-center justify-center rounded-md bg-white"
              >
                <p class="text-2xl text-gray-600">
                  {{ this.org_info.organizational_name }}
                </p>
                <h1 class="text-sm text-gray-600">Organization Name</h1>
              </div>
            </template>
          </div>
        </div>
        <div
          class="md:flex space-y-1 md:justify-between md:py-5 md:gap-x-2 p-3"
        >
          <div
            class="md:flex space-y-1 md:space-y-0 md:gap-x-5 gap-x-2 items-center justify-center"
          >
            <button
              @click="active_tab = 'user'"
              class="flex rounded-md p-2 item-center gap-x-1 md:gap-x-3 items-center h-10 w-60"
              :class="[
                active_tab === 'user' ? 'bg-[#2A84EF] text-white' : 'bg-white',
              ]"
            >
              <img
                v-if="active_tab === 'user'"
                src="../assets/user_active.png"
                alt="Organization"
                class="h-4 w-4 object-contain"
              />
              <img
                v-else
                src="../assets/user_inactive.png"
                alt="Organization"
                class="h-4 w-4 object-contain"
              />
              <p class="text-sm">User Management</p>
            </button>
            <button
              @click="active_tab = 'organ'"
              class="flex rounded-md p-2 item-center gap-x-1 md:gap-x-3 items-center h-10 w-60"
              :class="[
                active_tab === 'organ' ? 'bg-[#2A84EF] text-white' : 'bg-white',
              ]"
            >
              <img
                v-if="active_tab === 'organ'"
                src="../assets/organization_active.png"
                alt="Organization"
                class="h-4 w-4 object-contain"
              />
              <img
                v-else
                src="../assets/organization_inactive.png"
                alt="Organization"
                class="h-4 w-4 object-contain"
              />
              <p class="text-sm">Pending Users</p>
            </button>
          </div>

          <div class="flex justify-center items-center">
            <button
              @click="is_popup = true"
              class="text-white bg-black rounded-md h-10 flex items-center p-2 gap-x-2 font-semibold"
            >
              <img
                src="../assets/Add.png"
                alt="add"
                class="h-5 w-5 object-contain"
              />
              <p class="text-sm">Add</p>
            </button>
          </div>
        </div>

        <!-- Pending User Table -->
        <div
          class="text-left p-5 w-full flex flex-col gap-y-5 bg-white"
          v-if="active_tab == 'organ'"
        >
          <div class="md:flex justify-between w-full">
            <div>
              <h1>Total Users</h1>
              <p class="text-lg">{{ pending_user.length }}</p>
            </div>
            <div
              class="flex flex-col md:flex-row gap-y-1 gap-x-2 justify-center"
            >
              <div
                class="flex relative items-center h-8 w-auto outline-none gap-x-2 px-2 border rounded-md bg-gray-100"
              >
                <img src="../assets/search.png" class="h-4 w-4" alt="" /><input
                  type="text"
                  placeholder="Search..."
                  v-model="pe_search_val"
                  class="outline-none bg-gray-100 w-full md:w-auto"
                />
              </div>
              <select
                name=""
                id=""
                class="rounded-md px-2 h-8 w-full md:w-auto"
              >
                <option value="">Today</option>
                <option value="">Last Week</option>
                <option value="">Last Month</option>
              </select>
            </div>
          </div>

          <table class="w-full text-left">
            <thead class="p-5 text-gray-700">
              <tr class="">
                <th class="p-2 text-sm">Name</th>
                <th class="p-2 text-sm">Email</th>
                <th class="p-2 text-sm">Phone number</th>
                <th class="p-2 text-sm">User Name</th>
                <th class="p-2 text-sm">Action</th>
              </tr>
            </thead>
            <tbody class="w-full">
              <!--  <tr class=" text-left" v-for="(id, index) in 10" :key="id">
               <td class="p-2">{{ index + 1 }}</td> 
             {{ index + 1 }}-->
              <!-- <tr>
                <td colspan="5" class="text-center p-5">No Data Found</td>
              </tr> -->
              <tr
                class="text-left border-b"
                v-for="user in pendingUserFilter"
                :key="user"
              >
                <td class="flex p-2 items-center gap-x-2">
                  <img
                    src="../assets/Rectangle_313.png"
                    alt="Organization"
                    class=""
                  />
                  <p class="text-sm">{{ user.user_name }}</p>
                </td>
                <td class="p-2 text-sm">{{ user.user_email }}</td>
                <td class="p-2 text-sm">{{ user.phone_number }}</td>
                <td class="p-2 text-sm">{{ user.username }}</td>

                <td class="relative">
                  <div class="">
                    <button
                      @click="toggleOption(user.user_email)"
                      class="rounded-md text-white font-semibold px-3 py-1 flex gap-x-2 items-center"
                    >
                      <img
                        src="../assets/Frame_3466514.png"
                        alt="Organization"
                        class=""
                      />
                    </button>
                    <div
                      @click="toggleOption(user.user_email)"
                      v-if="
                        button_popup_val == user.user_email &&
                        button_popup == true
                      "
                      class="flex flex-col absolute -bottom-[90px] -left-[160px] items-start bg-[#ffffff] w-48 border z-50"
                    >
                      <div
                        @click="
                          (select_user_id = user.user_id),
                            (select_user_phone = user.phone_number),
                            (is_update_popup = true),
                            (select_user_name = user.user_name)
                        "
                        class="flex w-full cursor-pointer items-center gap-x-2 px-2 hover:bg-gray-100 text-gray-700 py-1.5 text-sm"
                      >
                        <img
                          src="../assets/Edit.png"
                          alt="edit"
                          class="object-contain h-4 w-4"
                        />Edit
                      </div>
                      <div
                        @click="activateUser(user.user_email)"
                        class="flex w-full cursor-pointer items-center gap-x-2 px-2 hover:bg-gray-100 text-gray-700 py-1.5 text-sm"
                      >
                        <img
                          src="../assets/onhold.png"
                          alt="edit"
                          class="object-contain h-4 w-4"
                        />Account Activate
                      </div>
                      <!-- <div
                        class="flex w-full cursor-pointer items-center gap-x-2 px-2 hover:bg-gray-100 text-gray-700 py-1.5 text-sm"
                      >
                        <img
                          src="../assets/suspend.png"
                          alt="edit"
                          class="object-contain h-4 w-4"
                        />Suspend Account
                      </div> -->
                      <div
                        @click="deleteUser(user.user_id)"
                        class="flex w-full cursor-pointer items-center gap-x-2 px-2 hover:bg-gray-100 text-gray-700 py-1.5 text-sm"
                      >
                        <img
                          src="../assets/delete.png"
                          alt="edit"
                          class="object-contain h-4 w-4"
                        />Delete Account
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- User Table -->
        <div
          class="text-left p-5 w-full flex flex-col gap-y-5 bg-white h-[calc(100vh-144px)] overflow-y-auto"
          v-if="active_tab == 'user'"
        >
          <div class="md:flex justify-between w-full">
            <div class="flex justify-evenly gap-8">
              <div>
                <h1 class="text-sm">Total Users</h1>
                <p class="text-lg">{{ this.user_count }}</p>
              </div>
            </div>
            <div class="flex gap-x-2 justify-center">
              <div
                class="flex relative items-center h-8 w-auto outline-none gap-x-2 px-2 border rounded-md bg-gray-100"
              >
                <img src="../assets/search.png" class="h-4 w-4" alt="" /><input
                  type="text"
                  placeholder="Search..."
                  v-model="search_val"
                  class="outline-none bg-gray-100 w-20 md:w-auto"
                />
              </div>
              <select name="" id="" class="rounded-md px-2 h-8 w-20 md:w-auto">
                <option value="">Today</option>
                <option value="">Last Week</option>
                <option value="">Last Month</option>
              </select>
            </div>
          </div>
          <!-- Table content -->
          <table class="w-full text-left">
            <thead class="p-5 text-gray-700">
              <tr class="">
                <th class="p-2 text-sm">Name</th>
                <th class="p-2 text-sm">Email</th>
                <th class="p-2 text-sm">Phone number</th>
                <th class="p-2 text-sm">User Name</th>
                <th class="p-2 text-sm">Action</th>
              </tr>
            </thead>
            <tbody class="w-full">
              <!--  <tr class=" text-left" v-for="(id, index) in 10" :key="id">
               <td class="p-2">{{ index + 1 }}</td> 
             {{ index + 1 }}-->
              <!-- <tr>
                <td colspan="5" class="text-center p-5">No Data Found</td>
              </tr> -->
              <tr
                class="text-left border-b"
                v-for="user in userFilter"
                :key="user"
              >
                <td class="flex p-2 items-center gap-x-2">
                  <img
                    src="../assets/Rectangle_313.png"
                    alt="Organization"
                    class=""
                  />
                  <p class="text-sm">{{ user.user_name }}</p>
                </td>
                <td class="p-2 text-sm">{{ user.user_email }}</td>
                <td class="p-2 text-sm">{{ user.phone_number }}</td>
                <td class="p-2 text-sm">
                  {{ user.username ? user.username : "" }}
                </td>

                <td class="relative">
                  <div>
                    <button
                      @click="toggleOption(user.user_email)"
                      class="rounded-md text-white font-semibold px-3 py-1 flex gap-x-2 items-center"
                    >
                      <img
                        src="../assets/Frame_3466514.png"
                        alt="Organization"
                        class=""
                      />
                    </button>
                    <div
                      @click="toggleOption(user.user_email)"
                      v-if="
                        button_popup_val == user.user_email &&
                        button_popup == true
                      "
                      class="flex flex-col absolute -bottom-[120px] -left-[160px] items-start bg-[#ffffff] w-48 border z-50"
                    >
                      <div
                        @click="
                          (select_user_id = user.user_id),
                            (select_user_phone = user.phone_number),
                            (is_update_popup = true),
                            (select_user_name = user.user_name)
                        "
                        class="flex w-full cursor-pointer items-center gap-x-2 px-2 hover:bg-gray-100 text-gray-700 py-1.5 text-sm"
                      >
                        <img
                          src="../assets/Edit.png"
                          alt="edit"
                          class="object-contain h-4 w-4"
                        />Edit
                      </div>
                      <div
                        @click="inActivateUser(user.user_email)"
                        class="flex w-full cursor-pointer items-center gap-x-2 px-2 hover:bg-gray-100 text-gray-700 py-1.5 text-sm"
                      >
                        <img
                          src="../assets/onhold.png"
                          alt="edit"
                          class="object-contain h-4 w-4"
                        />Account on hold
                      </div>
                      <div
                        @click="deleteUser(user.user_id)"
                        class="flex w-full cursor-pointer items-center gap-x-2 px-2 hover:bg-gray-100 text-gray-700 py-1.5 text-sm"
                      >
                        <img
                          src="../assets/delete.png"
                          alt="edit"
                          class="object-contain h-4 w-4"
                        />Delete Account
                      </div>
                      <router-link
                        class="flex gap-x-2 items-center w-full"
                        :to="{
                          name: 'DownloadHistory',
                          params: { id: user.user_id },
                        }"
                      >
                        <div
                          class="flex w-full cursor-pointer items-center gap-x-2 px-2 hover:bg-gray-100 text-gray-700 py-1.5 text-sm"
                        >
                          <font-awesome-icon
                            :icon="['fas', 'download']"
                            class="text-[#858585]"
                          />
                          Download History
                        </div>
                      </router-link>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Popup -->
        <div
          v-if="is_popup"
          class="w-full h-screen fixed top-0 right-0 bg-black bg-opacity-20 flex items-center justify-center text-white z-40 lato"
        >
          <div class="w-full md:w-[25%] bg-white absolute right-0 top-0 z-50">
            <!-- Popup content -->
            <div action="" class="p-5 flex flex-col mt-10">
              <div class="flex justify-between p-2 px-8">
                <h2 class="text-xl text-center text-black">Add User</h2>
                <button
                  @click="is_popup = false"
                  class="text-white last:rounded-md p-1 rounded-md"
                >
                  <img
                    src="../assets/Group_1321.png"
                    alt="close icon"
                    class=""
                  />
                </button>
              </div>
              <div class="bg-current min-h-screen flex justify-center pt-5">
                <div
                  class="bg-white relative rounded-lg w-full md:w-[85%] max-w-[500px]"
                >
                  <div class="mb-4">
                    <div class="relative">
                      <div
                        class="absolute inset-y-0 left-0 flex items-center pl-3"
                      >
                        <img
                          src="../assets/User.svg"
                          alt="User"
                          class="h-5 w-5 text-gray-400"
                        />
                      </div>
                      <input
                        id="name"
                        type="text"
                        name=""
                        v-model="new_user.name"
                        class="text-base text-black placeholder-gray-500 pl-10 pr-4 bg-[#EEF6FF] w-full py-2 focus:outline-none focus:border-blue-400"
                        placeholder="FullName"
                      />
                    </div>
                  </div>
                  <div class="mb-4">
                    <div class="relative">
                      <div
                        class="absolute inset-y-0 left-0 flex items-center pl-3"
                      >
                        <img
                          src="../assets/User.svg"
                          alt="User"
                          class="h-5 w-5 text-gray-400"
                        />
                      </div>
                      <input
                        id="name"
                        type="text"
                        name=""
                        v-model="user_name"
                        class="text-base text-black placeholder-gray-500 pl-10 pr-4 bg-[#EEF6FF] w-full py-2 focus:outline-none focus:border-blue-400"
                        placeholder="Username"
                      />
                    </div>
                  </div>
                  <div class="mb-4">
                    <div class="relative flex">
                      <div class="flex items-center pl-3 bg-[#EEF6FF]">
                        <div
                          ref="dropdownContent"
                          class="h-5 w-10 text-sm bg-[#EEF6FF] cursor-pointer text-gray-500"
                          @click="is_country_code = !is_country_code"
                        >
                          <img
                            :src="
                              require(`@/assets/flags/${this.country_flag}.svg`)
                            "
                            class="h-4 w-4 object-contain"
                            alt="india"
                          />
                        </div>
                      </div>
                      <input
                        id="mobile"
                        type="number"
                        name=""
                        v-model="new_user.mob"
                        class="text-base text-black placeholder-gray-500 pr-4 bg-[#EEF6FF] w-full py-3 focus:outline-none focus:border-blue-400"
                        placeholder="Mobile Number"
                        maxlength="10"
                        minlength="10"
                      />
                      <div
                        v-if="is_country_code"
                        class="absolute top-10 -left-1 bg-[#EEF6FF] z-50"
                      >
                        <div class="" ref="country_ref">
                          <div
                            class="max-h-[330px] overflow-y-auto shadow-xl rounded-sm p-2 border text-black"
                          >
                            <div class="custom-select-trigger text-gray-700">
                              Select an option
                            </div>
                            <div class="custom-options">
                              <span
                                v-for="cc in c_code"
                                :key="cc"
                                class="flex gap-2 items-center justify-start text-xs hover:bg-gray-100 py-1.5 px-2"
                                @click="
                                  (country_code = cc.dial_code),
                                    (is_country_code = false),
                                    (country_flag = cc.code)
                                "
                                ><img
                                  :src="
                                    require(`@/assets/flags/${cc.code}.svg`)
                                  "
                                  class="h-4 w-4 object-contain"
                                  alt="Option 1"
                                />
                                {{ cc.name }}
                                <span class="text-gray-500">{{
                                  cc.dial_code
                                }}</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mb-10">
                    <div class="relative">
                      <div
                        class="absolute inset-y-0 left-0 flex items-center pl-3"
                      >
                        <img
                          src="../assets/mail.svg"
                          alt="User"
                          class="h-5 w-5 text-gray-400 object-contain"
                        />
                      </div>
                      <input
                        id="email"
                        type="text"
                        name=""
                        v-model="new_user.email"
                        class="text-base text-black placeholder-gray-500 pl-10 pr-4 bg-[#EEF6FF] w-full py-2 focus:outline-none focus:border-blue-400"
                        placeholder="Enter Email"
                      />
                    </div>
                  </div>
                  <p
                    v-if="this.message.text != ''"
                    class="text-start capitalize"
                    :class="[
                      this.message.status == 200
                        ? ' text-green-700'
                        : ' text-red-700',
                    ]"
                  >
                    {{ this.message.text }}
                  </p>
                  <button
                    v-if="!is_loading"
                    @click="addUser"
                    type="submit"
                    class="w-full border h-[49px] rounded-lg font-bold bg-[#2A84EF] text-white mb-3"
                  >
                    Add User
                  </button>
                  <button
                    v-else
                    class="w-full border h-[49px] rounded-lg font-bold bg-[#2A84EF] text-white mb-3"
                  >
                    Loading...
                  </button>

                  <div class="flex items-center mb-6">
                    <span class="flex-grow border-b border-gray-300"></span>
                    <p class="text-sm text-gray-500 mx-3">or</p>
                    <span class="flex-grow border-b border-gray-300"></span>
                  </div>

                  <div class="flex justify-center">
                    <label
                      for="file-upload"
                      class="h-[3.5rem] cursor-pointer w-[158px] flex items-center justify-center mt-[10px] bg-black rounded-full p-3"
                    >
                      <span class="mr-[5px]">&#128206;</span>
                      <input
                        type="file"
                        id="file-upload"
                        @change="handleFileUpload"
                        class="file-upload-input"
                      />
                      Upload here
                    </label>
                  </div>
                  <p
                    v-if="this.message_bul.text != ''"
                    class="text-center capitalize py-2"
                    :class="[
                      this.message_bul.status == 200
                        ? ' text-green-700'
                        : ' text-red-700',
                    ]"
                  >
                    {{ this.message_bul.text }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Popup for Update the User -->
        <div
          v-if="is_update_popup"
          class="w-full h-screen fixed top-0 right-0 bg-black bg-opacity-20 flex items-center justify-center text-white z-40 lato"
        >
          <div class="w-full md:w-[25%] bg-white absolute right-0 top-0 z-50">
            <div action="" class="p-5 flex flex-col mt-10">
              <div class="flex justify-between p-2 px-8">
                <h2 class="text-xl text-center text-black">Update User</h2>
                <button
                  @click="is_update_popup = false"
                  class="text-white last:rounded-md p-1 rounded-md"
                >
                  <img src="../assets/Group_1321.png" alt="icon" class="" />
                </button>
              </div>
              <div class="bg-current min-h-screen flex justify-center pt-5">
                <div
                  class="bg-white relative rounded-lg w-full md:w-[85%] max-w-[500px]"
                >
                  <div class="mb-10">
                    <div class="relative">
                      <div
                        class="absolute inset-y-0 left-0 flex items-center pl-3"
                      >
                        <img
                          src="../assets/User.svg"
                          alt="User"
                          class="h-5 w-5 text-gray-400"
                        />
                      </div>
                      <input
                        id="name"
                        type="text"
                        name=""
                        v-model="select_user_name"
                        class="text-base text-black placeholder-gray-500 pl-10 pr-4 bg-[#EEF6FF] w-full py-2 focus:outline-none focus:border-blue-400"
                        placeholder="FullName"
                      />
                    </div>
                  </div>

                  <div class="mb-10">
                    <div class="relative">
                      <div
                        class="absolute inset-y-0 left-0 flex items-center pl-3"
                      >
                        <img
                          src="../assets/call.png"
                          alt="User"
                          class="h-5 w-5 text-gray-400 object-contain"
                        />
                      </div>
                      <input
                        id="phone"
                        type="text"
                        name=""
                        v-model="select_user_phone"
                        class="text-base text-black placeholder-gray-500 pl-10 pr-4 bg-[#EEF6FF] w-full py-2 focus:outline-none focus:border-blue-400"
                      />
                    </div>
                  </div>
                  <p
                    v-if="this.message.text != ''"
                    class="text-start capitalize"
                    :class="[
                      this.message.status == 200
                        ? ' text-green-700'
                        : ' text-red-700',
                    ]"
                  >
                    {{ this.message.text }}
                  </p>
                  <button
                    v-if="!is_loading"
                    @click="updateUser"
                    type="submit"
                    class="w-full border h-[49px] rounded-lg font-bold bg-[#2A84EF] text-white mb-3"
                  >
                    Update User
                  </button>
                  <button
                    v-else
                    class="w-full border h-[49px] rounded-lg font-bold bg-[#2A84EF] text-white mb-3"
                  >
                    Loading...
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="message_hold.text != ''"
      class="fixed top-2 right-2 w-[340px] p-2 bg-gray-100 min-h-[60px] rounded-md flex items-center justify-center text-sm capitalize"
      :class="
        this.message_hold.status != 200
          ? 'border border-red-400 bg-red-300'
          : 'border border-green-400 bg-green-300'
      "
    >
      {{ message_hold.text }}
    </div>
  </div>
</template>

<script>
import TopNavbar from "@/components/NewComponents/TopNavbar.vue";
import axios from "axios";
import CC from "@/services/coutryCode.js";
import { mapGetters } from "vuex";
export default {
  name: "SettingsView",
  components: { TopNavbar },
  data() {
    return {
      active_tab: "user",
      is_popup: false,
      selected: "Today",
      search: "",
      button_popup_val: "",
      user_list: [],
      message_hold: {
        text: "",
        status: "",
      },
      message: {
        text: "",
        status: "",
      },
      message_bul: {
        text: "",
        status: "",
      },
      new_user: {
        name: "",
        mob: "",
        email: "",
        site: "",
      },
      is_loading: false,
      user_count: 0,
      search_val: "",
      country_code: "+91",
      c_code: [],
      org_info: [],
      is_country_code: false,
      pending_user: [],
      country_flag: "in",
      button_popup: false,
      pe_search_val: "",
      select_user_id: "",
      select_user_phone: "",
      select_user_name: "",
      is_update_popup: false,
      user_name: "",
    };
  },
  computed: {
    ...mapGetters(["getUser", "getToken"]),
    userFilter() {
      return this.user_list.filter((el) =>
        el.user_email.includes(this.search_val)
      );
    },
    pendingUserFilter() {
      return this.pending_user.filter((el) =>
        el.user_email.includes(this.pe_search_val)
      );
    },
  },
  created() {
    this.c_code = CC.countryCode();
    this.userGetList();
    this.orgInfo();
    this.pendingUserList();
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    deleteUser(user_id) {
      axios
        .delete(
          `${process.env.VUE_APP_API}/api/delete-users/${user_id}/?Key=${this.getToken}`
        )
        .then((response) => {
          console.log(response.data.message);
          this.message_hold.text = response.data.message;
          this.message_hold.status = 200;
          this.button_popup_val = "";
          this.button_popup = false;
          this.userGetList();
          this.orgInfo();
          this.pendingUserList();
          setTimeout(() => {
            this.message_hold.text = "";
            this.message_hold.status = "";
          }, 5000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateUser() {
      this.is_loading = true;
      let data_json = {
        first_name: this.select_user_name,
        phone: this.select_user_phone,
      };
      axios
        .patch(
          `${process.env.VUE_APP_API}/api/edit-username-phone/${this.select_user_id}/?Key=${this.getToken}`,
          data_json
        )
        .then((response) => {
          this.is_loading = false;
          this.is_update_popup = false;
          console.log(response.data.response);
          this.message_hold.text = response.data.response;
          this.message_hold.status = 200;
          this.userGetList();
          this.orgInfo();
          this.pendingUserList();
          setTimeout(() => {
            this.message_hold.text = "";
            this.message_hold.status = "";
          }, 5000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    activateUser(email) {
      let data_json = {
        email: email,
      };
      axios
        .patch(
          `${process.env.VUE_APP_API}/api/pending-users-add/?Key=${this.getToken}`,
          data_json
        )
        .then((response) => {
          console.log(response.data.success, "activate");
          this.message_hold.text = response.data.success;
          this.message_hold.status = 200;
          this.button_popup_val = "";
          this.button_popup = false;
          this.userGetList();
          this.orgInfo();
          this.pendingUserList();
        })
        .catch((er) => {
          this.message_hold.text = er.response.data.error;
          this.message_hold.status = 400;
          console.log(er);
        });
      setTimeout(() => {
        this.message_hold.text = "";
        this.message_hold.status = "";
      }, 3000);
    },
    inActivateUser(email) {
      let data_json = {
        email: email,
      };
      axios
        .patch(
          `${process.env.VUE_APP_API}/api/make-inactive/?Key=${this.getToken}`,
          data_json
        )
        .then((response) => {
          console.log(response.data.success, "inActive");
          this.message_hold.text = response.data.success;
          this.message_hold.status = 200;
          this.button_popup_val = "";
          this.button_popup = false;
          this.userGetList();
          this.orgInfo();
          this.pendingUserList();
        })
        .catch((er) => {
          this.message_hold.text = er.response.data.error;
          this.message_hold.status = 400;
          console.log(er.response.data.error);
        });
      setTimeout(() => {
        this.message_hold.text = "";
        this.message_hold.status = "";
      }, 3000);
    },
    toggleOption(el) {
      if (el == this.button_popup_val) {
        this.button_popup = false;
        this.button_popup_val = "";
      } else {
        this.button_popup = true;
        this.button_popup_val = el;
      }
    },
    pendingUserList() {
      axios
        .get(
          `${process.env.VUE_APP_API}/api/pending-users/?Key=${this.getToken}`
        )
        .then((response) => {
          console.log(response, "pending user");
          this.pending_user = response.data.results;
        })
        .catch((er) => {
          console.log(er);
        });
    },
    handleClickOutside(event) {
      if (
        this.is_country_code &&
        !this.$refs.country_ref.contains(event.target) &&
        !this.$refs.dropdownContent.contains(event.target)
      ) {
        this.is_country_code = false;
      }
    },

    orgInfo() {
      axios
        .get(
          `${process.env.VUE_APP_API}/api/org-holder-info/?Key=${this.getToken}`
        )
        .then((response) => {
          console.log(response, "org_info");
          this.org_info = response.data;

          console.log(this.org_info, "ortg info");
        })
        .catch((er) => {
          console.log(er);
        });
    },
    userGetList() {
      axios
        .get(`${process.env.VUE_APP_API}/api/user-list/?Key=${this.getToken}`)
        .then((response) => {
          console.log(response);
          this.user_list = response.data.results;
          this.user_count = response.data.count;
        })
        .catch((er) => {
          console.log(er);
        });
    },
    handleFileUpload(el) {
      console.log(el.target.files[0]);
      this.message_bul.text = "Uploading...";
      const form_data = new FormData();
      form_data.append("file", el.target.files[0]);
      axios
        .post(
          `${process.env.VUE_APP_API}/api/user-excel-upload/?Key=${this.getToken}`,
          form_data
        )
        .then((resp) => {
          console.log(resp);
          this.userGetList();
          this.orgInfo();
          this.pendingUserList();
          this.message_bul.text = `${resp.data.acc_created} account created and ${resp.data.acc_already_exist} is already exist`;
          this.message_bul.status = 200;
          setTimeout(() => {
            this.message_bul.text = "";
            this.message_bul.status = "";
          }, 3000);
        })
        .catch((er) => {
          console.log(er);
          this.message_bul.text = er.response.data.email[0];
          this.message_bul.status = 400;
          setTimeout(() => {
            this.message_bul.text = "";
            this.message_bul.status = "";
          }, 3000);
        });
    },
    addUser() {
      this.is_loading = true;
      let data_json = {
        email: this.new_user.email,
        phone: this.new_user.mob,
        country_code: this.country_code,
        site: "",
        first_name: this.new_user.name,
        last_name: "",
        username: this.user_name,
      };
      axios
        .post(
          `${process.env.VUE_APP_API}/api/user-add/?Key=${this.getToken}`,
          data_json
        )
        .then((resp) => {
          console.log(resp);
          this.userGetList();
          this.orgInfo();
          this.pendingUserList();
          this.message.text = "Account Created";
          this.message.status = 200;
          this.new_user.email = "";
          this.new_user.name = "";
          this.new_user.mob = "";
          this.new_user.site = "";
          this.user_name = "";
          setTimeout(() => {
            this.message.text = "";
            this.message.status = "";
            this.is_loading = false;
          }, 3000);
        })
        .catch((er) => {
          console.log(er);
          this.message.text = JSON.stringify(er.response.data).replace(
            /\[|]|{|}|"/g,
            " "
          );
          this.message.status = 400;
          setTimeout(() => {
            this.message.text = "";
            this.message.status = "";
            this.is_loading = false;
          }, 3000);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
html,
body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 16px;
}

div#app {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.file-upload-label {
  display: inline-block;
  border: 1px dotted #777777; /* Dotted border */
  padding: 10px;
  cursor: pointer;
}

.file-upload-input {
  display: none; /* Hide the file input */
}
</style>
