<template>
  <div class="flex">
    <div class="flex-1 bg-[#F1F8FE] w-full">
      <TopNavbar />
      <div class="h-[calc(100vh-60px)] overflow-y-auto">
        <div class="p-8">
          <h1 class="text-[22px] font-bold text-gray-900 mb-6">
            Organization List
          </h1>
          <div class="relative">
            <div class="flex items-center justify-between">
              <div
                class="w-full pl-7 flex items-center h-20 rounded-tl-3xl bg-blue-100"
              >
                <span class="text-lg font-medium"
                  >{{ user_info.length }} Users</span
                >
              </div>
              <!-- <div class="h-20 w-72 rounded-tr-full bg-blue-100"></div>
              <div
                class="flex h-20 z-20 bg-[#F1F8FE] items-center gap-2 w-auto p-3 px-5 -mx-[9px] rounded-b-[50px] relative"
              >
                <button
                  class="px-1 w-20 py-3 rounded-full bg-blue-500 text-white text-xs"
                >
                  All Users
                </button>
                <button
                  class="px-4 py-3 w-20 rounded-full bg-blue-100 text-blue-500 text-xs font-medium"
                >
                  Active
                </button>
                <button
                  class="px-4 w-20 py-3 rounded-full bg-blue-100 text-blue-500 text-xs font-medium"
                >
                  Expired
                </button>
              </div>
              <span
                class="absolute w-96 h-9 left-1/2 -translate-x-1/2 -bottom-1 bg-blue-100"
              ></span>
              <div class="h-20 w-72 rounded-tl-full bg-blue-100"></div> -->
              <div
                class="flex w-full p-7 rounded-tr-3xl h-20 bg-blue-100 justify-end items-center gap-2"
              >
                <div class="relative">
                  <input
                    type="text"
                    placeholder="Search users here....."
                    class="w-[180px] pl-10 pr-4 py-2 rounded-full bg-white shadow-sm text-sm"
                  />
                  <svg
                    class="w-4 h-4 absolute left-4 top-1/2 -translate-y-1/2 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <circle cx="11" cy="11" r="8" />
                    <path d="M21 21l-4.35-4.35" />
                  </svg>
                </div>
                <button class="text-gray-600 bg-white rounded-full px-1 py-1">
                  <svg
                    class="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="bg-blue-100 rounded-b-[40px] relative p-6">
            <div class="bg-white rounded-xl shadow-sm p-3">
              <table class="w-full border-separate border-spacing-y-3">
                <thead>
                  <tr class="text-left bg-blue-100">
                    <th
                      class="py-4 px-4 rounded-l-xl text-sm font-medium text-gray-700"
                    >
                      No
                    </th>
                    <th class="text-sm font-medium text-gray-700">Name</th>
                    <th class="text-sm font-medium text-gray-700">User Name</th>
                    <th class="py-4 px-4 text-sm font-medium text-gray-700">
                      Email
                    </th>
                    <th class="py-4 px-4 text-sm font-medium text-gray-700">
                      Phone Number
                    </th>
                    <th class="py-4 px-4 text-sm font-medium text-gray-700">
                      Access Type
                    </th>
                    <th class="py-4 px-4 text-sm font-medium text-gray-700">
                      Organization
                    </th>
                    <th class="py-4 px-4 text-sm font-medium text-gray-700">
                      Joined Date
                    </th>
                  </tr>
                </thead>
                <tbody class="">
                  <tr
                    class="text-left py-2 border rounded-lg cursor-pointer hover:bg-blue-500 hover:text-white group transition-colors"
                    @click="(popUp = true), (select_user = user)"
                    v-for="(user, index) in user_info"
                    :key="user"
                    :class="(index + 1) % 2 == 0 ? 'bg-gray-200' : 'bg-white'"
                  >
                    <td class="px-4 py-2 text-sm rounded-l-xl">
                      {{ index + 1 }}
                    </td>
                    <td class="gap-3">
                      <div class="flex items-center">
                        <span
                          class="bg-blue-500 w-7 mr-1 flex justify-center items-center group-hover:bg-white group-hover:text-blue-500 h-7 text-center group-hover:font-semibold place-content-center rounded-full text-sm text-white"
                          >{{ user.first_name.slice(0, 1) }}</span
                        >
                        <span class="text-sm font-medium">
                          {{ user.first_name }}</span
                        >
                      </div>
                    </td>
                    <td class="px-4 py-2 text-sm">{{ user.username }}</td>
                    <td class="px-4 py-2 text-sm">{{ user.email }}</td>
                    <td class="px-4 py-2 text-sm">{{ user.phone }}</td>
                    <td class="px-4 py-2 text-sm">
                      {{ user.Permission_access }}
                    </td>
                    <td class="px-4 py-2 text-sm">{{ user.organization }}</td>
                    <td class="px-4 py-2 text-sm">
                      {{
                        new Date(user.date_joined).toLocaleDateString("en-GB")
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="flex items-center justify-between px-2 mt-3">
              <div class="flex items-center gap-2 text-sm text-gray-600">
                <span>Show</span>
                <span class="bg-white rounded-full px-2 py-2"
                  ><option class="font-bold">10</option></span
                >

                <span>entries</span>
              </div>

              <div class="p-4 rounded-lg">
                <div class="flex items-center gap-4">
                  <span class="text-sm text-gray-400">
                    Showing 1 to 10 of 121 entries
                  </span>
                  <div class="flex items-center gap-2">
                    <div class="mx-auto my-4">
                      <PaginationViewVue
                        @selectedPage="getUserInfo"
                        :total_number_page="this.total_page"
                        :count_per_page="this.count_per_page"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TopNavbar from "@/components/NewComponents/TopNavbar.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      user_info: [],
    };
  },
computed: {
    ...mapGetters(["getToken"]),
  },
  components: { TopNavbar },
  created() {
    axios
      .get(
        `${process.env.VUE_APP_API}/api/list-admins-all/?Key=${this.getToken}`
      )
      .then((response) => {
        console.log(response);
        this.user_info = response.data;
      });
  },
};
</script>

<style></style>
